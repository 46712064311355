import React, {useState} from 'react';
import {Alert, Button, Select} from "antd";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getStatusList} from "../../../services/statuses";
import classes from "../styles.module.css"
import {assignWorkOrderStatus} from "../../../services/workOrders";


interface StatusListProps {
    currentStatus: string
    workOrderId: string
}

const StatusList:React.FC<StatusListProps> = ({currentStatus, workOrderId}) => {

    const [error, setError] = useState("");
    const [status, setStatus] = useState<string>(currentStatus);
    const queryClient = useQueryClient()

    const {data: statuses, isLoading} = useQuery({
        queryFn: () => getStatusList(),
        queryKey: ["statusList"],
    })

    const onChange = (value: string) => {
        console.log(`selected ${value}`);
        setStatus(value)
    };

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    const mutation = useMutation({
        mutationFn: () => assignWorkOrderStatus(status, workOrderId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ['workOrderDetails']}).catch(console.error)
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });


    if (isLoading) return (<div>Loading...</div>)

    const options = statuses?.status.map((status: any) => ({
        value: status.id,
        label: status.name,
    }));


    const handleChangeStatus = () => {
        // @ts-ignore
        mutation.mutate({
           newStatusId: status,
            workOrderId: workOrderId
        });
    }

    return (
        <div>
            <h2>Status:</h2>
            <div className={classes.statusRow}>
                <Select
                    style={{width: "200px"}}
                    defaultValue={status}
                    showSearch
                    optionFilterProp="label"
                    onChange={onChange}
                    onSearch={onSearch}
                    options={options}
                />
                <Button type={"primary"} onClick={handleChangeStatus}>Change status</Button>
            </div>
            {error && <Alert message={error} type={"error"} className={classes.alert}/>}
        </div>
    );
};

export default StatusList;