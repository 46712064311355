import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Card,
    CheckboxOptionType, Divider,
    Dropdown,
    Input, Menu, MenuProps,
    Radio,
    RadioChangeEvent,
    RadioGroupProps, Select
} from "antd";
import classes from "./style.module.css"
import {PlusOutlined} from "@ant-design/icons";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getProductAddons} from "../../../../services/products";
import {Addon, Product} from "../../../../types/types";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {FormProduct} from "../../../../types/inputs";
import {v4 as uuidv4} from "uuid";
import TextArea from "antd/es/input/TextArea";
import ProductAddonList from "./productAddons/ProductAddonList";
import {optionsWithDisabled} from "../../../../utils/globalFunctions";
import {deleteProductsInstalled} from "../../../../services/workOrders";
import Clipboard from "../../UI/Clipboard";

interface ProductInputItemProps extends RadioGroupProps {
    productInput: FormProduct
    options: (string | number | CheckboxOptionType<CheckboxValueType>)[]
    handleUpdateInput: (id: string, field: string, value: any) => void
}

const ProductInputChooser: React.FC<ProductInputItemProps> = (props) => {

    const {
        productInput,
        options,
        handleUpdateInput,
        id,
        ...radioGroupProps
    } = props

    if (productInput.serialNumber != null) {
        return (
            <>
                <div className={classes.clipboardContainer}>
                    <Input
                        style={{minWidth: "200px"}}
                        type={"text"}
                        defaultValue={productInput.serialNumber}
                        onChange={(e) => handleUpdateInput(productInput.itemNumber, "serialNumber", e.target.value)}
                        placeholder={"serial number"}
                    />
                    <Clipboard text={productInput.serialNumber}/>
                </div>

                <Radio.Group
                    options={options}
                    {...radioGroupProps}
                    value={productInput.quantity.toString() ?? "1"}
                    optionType="button"
                    buttonStyle="solid"
                    className={classes.radioRow}
                />
            </>
        );
    }

    return (
        <Input
            style={{minWidth: "200px"}}
            defaultValue={productInput.quantity}
            onChange={(e) => handleUpdateInput(productInput.itemNumber, "quantity", e.target.value)}
            type={"number"}
            placeholder={"quantity"}
            suffix={productInput.units}
            required
        />
    );
}

interface IProductItemProps {
    initProductAddons: FormProduct[];
    productInput: FormProduct
    handleRemoveProductInput: (product: FormProduct) => void
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void
    workOrderId: string;
}

const ProductInputItem: React.FC<IProductItemProps> = (props) => {

    const {
        handleRemoveProductInput,
        productInput,
        initProductAddons,
        setProductInputs,
        workOrderId
    } = props

    const [value, setValue] = useState(productInput.quantity ? productInput.quantity.toString() : "1");
    const [productInputAddons, setProductInputAddons] = useState<FormProduct[]>(initProductAddons);
    const [dropdownAddonsList, setDropdownAddonsList] = useState<{
        value: string; key: string, label: React.ReactNode
    }[]>([]);
    const [error, setError] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
    const queryClient = useQueryClient();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const {data: productAddons, isLoading} = useQuery({
        queryFn: () => getProductAddons(productInput.productId),
        queryKey: ["productAddons", productInput.productId],

        //@ts-ignore
        onSuccess: (data: any) => {
            const newProductAddons = getDropdownItems(data)
            setDropdownAddonsList(newProductAddons)
        }
    });

    const deleteMutation = useMutation<any, Error, { workOrderId: string, itemNumber: string }>({
        mutationFn: ({workOrderId, itemNumber}) => deleteProductsInstalled(workOrderId, itemNumber),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['productsInstalled']}).catch(console.error);
            setError("");
        },
        onError: (error) => {
            setError(error.message);
        }
    });


    const handleUpdateInput = (id: string, field: string, value: string | number) => {

        setProductInputs((prevInputs) =>
            prevInputs.map((input: FormProduct) =>
                input.itemNumber === id ? {...input, [field]: value} : input
            )
        );

        setProductInputAddons((prevInputs) =>
            prevInputs.map((input: FormProduct) =>
                input.itemNumber === id ? {...input, [field]: value} : input
            ))

    };


    const onChange = ({target: {value}}: RadioChangeEvent) => {
        setValue(value);
        handleUpdateInput(productInput.itemNumber, "quantity", value)
    };


    const handleSelectProductAddon = (product: Product) => {

        const itemNumber = uuidv4()

        const newInput: FormProduct = {
            productId: product.id,
            units: product.units,
            ...(product.serialNumber ? {serialNumber: ''} : {}),
            quantity: product.serialNumber ? "1" : '1',
            itemNumber,
            parentItemNumber: productInput.itemNumber,
            productName: product.name,
            description: ""
        };

        setProductInputs(prev => [...prev, {...newInput}]);

        setProductInputAddons(prev => {
            if (prev) {
                return [...prev, {...newInput}]
            } else {
                return [{...newInput}]
            }
        })
    };


    const getDropdownItems = (addons: Omit<Addon, "itemNumber">[] | undefined) => {
        return (Array.isArray(addons) ? addons : [])
            ?.sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name' property alphabetically
            .map((addon: Omit<Addon, "itemNumber">, index) => ({
                key: String(index + 1),
                value: addon.name,
                label: (
                    <div className={isMobile ? classes.mobileDropdownItem : classes.dropdownItem}
                         onClick={handleSelectProductAddon.bind(this, addon)}>
                        {addon.name}
                        {isMobile ? <Divider/> : <></>}
                    </div>
                ),
            }));
    };

    useEffect(() => {
        if (productAddons) {
            setDropdownAddonsList(getDropdownItems(productAddons))
        }
    }, [productAddons]);

    const dropdownItems = isLoading ? [] : dropdownAddonsList;

    const handleRemoveAddon = (addon: FormProduct) => {

        setProductInputs(prev => prev.filter(i => i.itemNumber !== addon.itemNumber))
        setProductInputAddons((productInputAddons || []).filter(i => i.itemNumber !== addon.itemNumber))

        deleteMutation.mutate({
            workOrderId: workOrderId,
            itemNumber: addon.itemNumber
        })

    }

    const [selectedValue, setSelectedValue] = useState(null);

    const handleSelectChange = () => {
        setSelectedValue(null);
    };

    return (
        <div>
            <Card className={classes.cardBorder}>
                <h3 style={{width: "200px"}}>{productInput.productName}</h3>
                <div className={classes.container}>
                    <ProductInputChooser handleUpdateInput={handleUpdateInput}
                                         productInput={productInput} value={value}
                                         options={optionsWithDisabled} onChange={onChange}/>
                    {isMobile ? <FullScreenDropdown dropdownItems={dropdownItems}/> : <Select
                        showSearch
                        style={{width: 300, maxWidth: "100%"}}
                        dropdownStyle={{width: 300}}
                        placeholder="+ Add addons"
                        filterSort={(optionA, optionB) =>
                            (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())
                        }
                        options={dropdownItems}
                        value={selectedValue}
                        onChange={handleSelectChange}
                    />}
                    <Button onClick={handleRemoveProductInput.bind(this, productInput)} danger>Remove</Button>
                </div>
                <br/>
                <div className={classes.container}>
                    <TextArea
                        defaultValue={productInput.description}
                        onChange={(e: any) => handleUpdateInput(productInput.itemNumber, "description", e.target.value)}
                        placeholder={"description"} autoSize={true}/>
                </div>
                <ProductAddonList addons={productInputAddons} handleUpdateInput={handleUpdateInput}
                                  handleRemoveAddon={handleRemoveAddon}/>
                <br/>
                {error && <Alert message={error} type={"error"} className={classes.errorText}/>}
            </Card>
        </div>
    );
};

export default ProductInputItem;

interface FullScreenDropdownProps {
    dropdownItems: {
        value: string; key: string, label: React.ReactNode
    }[];
}

const FullScreenDropdown: React.FC<FullScreenDropdownProps> = ({dropdownItems}) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    // Filter dropdown items based on the search term
    const filteredItems = dropdownItems.filter(item =>
        item.value.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Define menu items based on filtered data
    const menuItems: MenuProps['items'] = filteredItems.map(item => ({
        key: item.key,
        label: item.label,
    }));

    // Full-screen dropdown content with search and list
    const FullScreenMenu = (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',          // Full viewport height
            width: '100vw',           // Full viewport width
            padding: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.95)', // Slight transparency
            boxSizing: 'border-box',
            position: 'fixed',        // Fixed to cover entire viewport
            top: 0,
            left: 0,
            zIndex: 1000,
        }}>
            {/* Search Input */}
            <Input
                placeholder="Search addons..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                    margin: '20px 0',
                    width: '100%',
                }}
            />

            {/* Render the filtered items as a Menu */}
            <Menu
                style={{
                    flex: 1,
                    overflowY: 'auto',   // Only the Menu scrolls
                    width: '100%',
                }}
                items={menuItems}
            />
        </div>
    );

    return (
        <Dropdown
            menu={{items: menuItems}}
            disabled={dropdownItems.length === 0}
            placement="top"  // Set the dropdown placement to appear from the top
            dropdownRender={() => FullScreenMenu} // Use dropdownRender to customize menu appearance
            getPopupContainer={() => document.body} // Append dropdown to body for full-screen effect
        >
            <Button icon={<PlusOutlined/>}>Add addon</Button>
        </Dropdown>
    );
};


