import React from 'react';
import {Button, message, Space} from "antd";
import {CopyOutlined} from "@ant-design/icons";

interface ClipboardProps {
    text: string | undefined
}

const Clipboard: React.FC<ClipboardProps>= ({text}) => {
    const [messageApi, contextHolder] = message.useMessage();

    const copyToClipboard = async (text: string | undefined) => {
        if (text) {
            await navigator.clipboard.writeText(text)
            await messageApi.open({
                type: 'success',
                content: 'Copied!',
            });
        }
    }

    return (
        <>
            {contextHolder}
            <Space>
                <Button
                    onClick={copyToClipboard.bind(null, text)}><CopyOutlined/></Button>
            </Space>
        </>
    );
};

export default Clipboard;