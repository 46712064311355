import React, {useState} from 'react';
import {Button, Card, Input, Space, Tag} from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import classes from "../../styles.module.css"
import {FormSelect} from "../../../../types/inputs";


interface SelectCreatorProps {
    handleRemove: (remove: string) => void
    select: FormSelect
    onInputChange: (id: string, field: string, value: string) => void
    handleCheckboxChange: (id: string, checked: boolean) => void
    handleAddOption: (inputValue: string, id: string) => void
    handleRemoveOption: (e: any, id: string, selectId: string) => void
}

const SelectCreator: React.FC<SelectCreatorProps> = (props) => {
    const {
        handleRemove,
        select,
        handleCheckboxChange,
        onInputChange,
        handleRemoveOption,
        handleAddOption,
    } = props

    const [inputValue, setInputValue] = useState("");

    return (
        <Card className={classes.inputCreatorCard}>

            <div className={classes.inputRow}>
                <h3>Select:</h3>
                <div className={classes.inputMiddleRow}>
                    {/*<Select placeholder={"select"} options={select.options} style={{width: "200px"}}/>*/}
                    <Space.Compact>
                        <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)}
                               placeholder="Add new option"/>
                        <Button onClick={() => {
                            handleAddOption(inputValue, select.id)
                            setInputValue("");
                        }} type="primary">Add</Button>
                    </Space.Compact>
                    <Input value={select.label} style={{width: "auto"}} placeholder={"label"}
                           suffix={<span className={classes.red}>*</span>}
                           onChange={(e) => onInputChange(select.id, 'label', e.target.value)}/>
                    <Input value={select.name} style={{width: "auto"}} placeholder={"name"}
                           suffix={<span className={classes.red}>*</span>}
                           onChange={(e) => onInputChange(select.id, 'name', e.target.value)}/>

                </div>
                <div className={classes.row}>
                    <Checkbox checked={select.isRequired} onChange={(e) => {
                        handleCheckboxChange(select.id, e.target.checked)
                    }}>Required</Checkbox>
                    <Button danger onClick={handleRemove.bind(this, select.id)}>Remove</Button>
                </div>

            </div>
            <div className={classes.optionsList}>
                {select.options.map(option => <Tag key={option.id} style={{width: "auto"}} onClose={(e) => {
                    handleRemoveOption(e, option.id, select.id)
                }} closable={true}>{option.value}</Tag>)}
            </div>
        </Card>
    );
};

export default SelectCreator;