import {isNull} from "lodash";
import {WorkOrder} from "../../../../types/types";


const searchWorkOrderByTitle = (searchTerm: string, workOrder: WorkOrder): boolean => {

    if (isNull(workOrder.title)) return false;

    if (searchTerm.length === 0) return true;

    const searchTermLower = searchTerm.toLowerCase();

    const matchInTitleOrText = (str: string) =>
        searchTermLower.split(' ').every(term =>
            str.toLowerCase().includes(term)
        );

    const isMatchInTitle = matchInTitleOrText(workOrder.title || '');
    const isMatchInNotes = matchInTitleOrText(workOrder.notes || '');

    return isMatchInTitle || isMatchInNotes
};

const searchWorkOrderByStatus = (status: string | undefined, workOrder: WorkOrder): boolean => {

    if (status === undefined) return true

    return workOrder.statusId === status
}

//
// const searchTaskByPriority = (priority: string | undefined, task: Task): boolean => {
//
//     if (priority === undefined) return true
//
//     return task.priority === priority
// }
//
// const searchTaskByType = (type: string | undefined, task: Task): boolean => {
//
//     if (type === undefined) return true
//
//     return task.taskKind === type
// }
//
// const searchTaskByUser = (user: string | undefined, task: Task): boolean => {
//
//     if (user === undefined) return true
//
//     return task.users.some(taskUser => taskUser.userName === user);
// }
//
//
// const searchTaskByGroup = (group: string | undefined, task: Task): boolean => {
//
//     if (group === undefined) return true
//
//     return task.groups.some(taskGroup => taskGroup.groupName === group);
// }
//
// const sortByCreatedTime = (sortValue: number | undefined, a: Task, b: Task) => {
//
//     const dateA = new Date(a.createdDate);
//     const dateB = new Date(b.createdDate);
//
//     switch (sortValue) {
//         case 0:
//             return 0;
//         case 1:
//             return dateA.getTime() - dateB.getTime();
//         case 2:
//             return dateB.getTime() - dateA.getTime();
//         default:
//             return 0;
//     }
//
// }


// const sortByUpdatedTime = (sortValue: number, a: Task, b: Task) => {
//
//     const dateA = new Date(a.updatedDate);
//     const dateB = new Date(b.updatedDate);
//
//     switch (sortValue) {
//         case 0:
//             return 0;
//         case 1:
//             return dateA.getTime() - dateB.getTime();
//         case 2:
//             return dateB.getTime() - dateA.getTime();
//         default:
//             return 0;
//     }
//
// }


export const useWorkOrderFilters = (workOrders: WorkOrder[] | undefined, searchTerm: string, status: string | undefined) => {

    // If all filter values are null or undefined, return the initial array of tasks
    if (searchTerm.length === 0 && !status) {
        return {filteredWorkOrders: workOrders};
    }

    const filteredWorkOrders = workOrders?.filter(searchWorkOrderByTitle.bind(null, searchTerm))
        .filter(searchWorkOrderByStatus.bind(null, status))
        // .filter(searchTaskByPriority.bind(null, priority))
        // .filter(searchTaskByType.bind(null, type))
        // .filter(searchTaskByUser.bind(null, user))
        // .filter(searchTaskByGroup.bind(null, group))
        // .toSorted(sortByCreatedTime.bind(null, sortValue))
        // .toSorted(sortByUpdatedTime.bind(null, sortValue))


    return {filteredWorkOrders: filteredWorkOrders}
}