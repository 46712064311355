import React, {useEffect, useState} from 'react';
import {WorkOrder} from "../../../../types/types";
import {Button, Input, Select} from "antd";
import classes from "./style.module.css";
import {FilterOutlined, SearchOutlined} from "@ant-design/icons";
import {useWorkOrderFilters} from "./useWorkOrderFilters";
import {useQuery} from "@tanstack/react-query";
import {getStatusList} from "../../../../services/statuses";


interface WorkOrderFiltersProps {
    workOrders: WorkOrder[] | undefined
    setFilteredWorkOrders: (wOrders: WorkOrder[] | undefined) => void
}

const WorkOrderFilters: React.FC<WorkOrderFiltersProps> = ({workOrders, setFilteredWorkOrders}) => {

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [status, setStatus] = useState<string | undefined>(undefined);

    const {filteredWorkOrders} = useWorkOrderFilters(workOrders, searchTerm, status)

    const handleClearFilters = () => {
        setSearchTerm("")
        setStatus(undefined)
    }

    const handleChangeStatus = (value: string) => {
        setStatus(value)
    };

    useEffect(() => {
        setFilteredWorkOrders(filteredWorkOrders);
    }, [searchTerm, status]);


    const {data: statuses} = useQuery({
        queryFn: () => getStatusList(),
        queryKey: ["statusList"],
    })

    const options = statuses?.status.map((status: any) => ({
        value: status.id,
        label: status.name,
    }));


    return (
        <div className={classes.row}>
            <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className={classes.search}
                   suffix={<SearchOutlined/>} placeholder="Search work orders"/>
            <Select
                onChange={handleChangeStatus}
                placeholder="status"
                style={{width: 130}}
                value={status}
                allowClear
                options={options}
            />
            <Button className={classes.clearButton} icon={<FilterOutlined/>} onClick={handleClearFilters}>Clear
                filters</Button>
        </div>
    );
};

export default WorkOrderFilters;