import React from 'react';
import {Button, Card, Input, Select} from "antd";
import classes from "../../styles.module.css"
import Checkbox from "antd/es/checkbox/Checkbox";
import {FormInput} from "../../../../types/inputs";

interface InputCreatorProps {
    handleRemove: (remove: string) => void
    input: FormInput
    onInputChange: (id: string, field: string, value: string) => void
    handleCheckboxChange: (id: string, checked: boolean) => void
}

const InputCreator: React.FC<InputCreatorProps> = (props) => {
    const {
        input,
        handleRemove,
        onInputChange,
        handleCheckboxChange
    } = props

    return (
        <Card className={classes.inputCreatorCard}>
            <div className={classes.inputRow}>
                <h3>Input:</h3>
                <div className={classes.inputMiddleRow}>
                    {/*<Input placeholder={"input"} style={{width: 'fit-content'}}/>*/}
                    <Input
                        value={input.label}
                        style={{width: 'auto'}}
                        suffix={<span className={classes.red}>*</span>}
                        placeholder="Label"
                        onChange={(e) => onInputChange(input.id, 'label', e.target.value)}
                    />
                    <Input
                        value={input.name}
                        style={{width: 'auto'}}
                        suffix={<span className={classes.red}>*</span>}
                        placeholder="Name"
                        onChange={(e) => onInputChange(input.id, 'name', e.target.value)}
                    />
                    <Input
                        value={input.maxLength}
                        style={{width: 'auto'}}
                        placeholder="maxLength"
                        onChange={(e) => onInputChange(input.id, 'maxLength', e.target.value)}
                    />
                    <Input
                        style={{width: 'auto'}}
                        //@ts-ignore
                        value={input.suffix}
                        placeholder="suffix (ex. km)"
                        onChange={(e) => onInputChange(input.id, 'suffix', e.target.value)}
                    />
                    <span>Type</span>
                    <Select
                        defaultValue="text"
                        style={{width: 170}}
                        onChange={(value) => onInputChange(input.id, 'type', value)}
                        options={[
                            {value: 'text', label: 'text'},
                            {value: 'email', label: 'email'},
                            {value: 'number', label: 'number'},
                            {value: 'password', label: 'password'},
                        ]}
                    />

                </div>
                <div className={classes.row}>
                    <Checkbox
                        checked={input.isRequired}
                        onChange={(e) => handleCheckboxChange(input.id, e.target.checked)}
                    >
                        Required
                    </Checkbox>
                    <Button danger onClick={() => handleRemove(input.id)}>
                        Remove
                    </Button>
                </div>
            </div>
        </Card>

    );
};

export default InputCreator;