import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Button, Tabs, TabsProps} from "antd";
import classes from "./styles.module.css";
import {AppstoreOutlined, PlusOutlined, TableOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import WorkOrderSkeleton from "./components/WorkOrderSkeleton";
import {getMyWorkOrders} from "../../services/workOrders";
import CardView from "./Views/CardView";
import TableView from "./Views/TableView";
import TemplateModal from "./modals/TemplateModal";
import WorkOrderFilters from "./components/workOrderFilters/WorkOrderFilters";

interface ButtonsProps {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const ButtonActions: React.FC<ButtonsProps> = ({setIsModalOpen}) => {

    const navigate = useNavigate()

    return <>
        <Button onClick={() => {
            navigate(`/work_orders/template`)
        }} icon={<PlusOutlined/>}>Create new work order template</Button>
        <Button type={"primary"} onClick={() => {
            setIsModalOpen(true)
        }} icon={<PlusOutlined/>}>Create new work order</Button></>
}

const WorkOrdersPage = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust width as per your mobile breakpoint
        };

        handleResize(); // Check on initial render
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const {data: workOrders, isLoading} = useQuery({
        queryFn: () => getMyWorkOrders(),
        queryKey: ["workOrders"]
    })

    const [filteredWorkOrders, setFilteredWorkOrders] = useState(workOrders);

    useEffect(() => {
        setFilteredWorkOrders(workOrders);
    }, [workOrders]);

    const onChange = (key: string) => {
        // console.log(key);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Card View',
            children: <CardView workOrders={filteredWorkOrders}/>,
            icon: <AppstoreOutlined />
        },
        {
            key: '2',
            label: 'Table View',
            children: <TableView data={filteredWorkOrders}/>,
            icon: <TableOutlined/>,
            disabled: isMobile
        },
    ];

    if (isLoading) return <WorkOrderSkeleton/>

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <h2>List of work orders</h2>
                <div className={classes.row}>
                    <ButtonActions setIsModalOpen={setIsModalOpen}/>
                </div>
            </div>
            <WorkOrderFilters workOrders={workOrders} setFilteredWorkOrders={setFilteredWorkOrders}/>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange}/>
            <TemplateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </div>
    );
};

export default WorkOrdersPage;