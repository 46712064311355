import React, {useState} from 'react';
import {Alert, Button, Space, Table, TableProps, Tag} from "antd";
import {WorkOrder} from "../../../types/types";
import {formatDate} from "../../../utils/globalFunctions";
import {ArrowRightOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import WarningModal from "../../../components/modals/WarningModal/WarningModal";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteWorkOrder} from "../../../services/workOrders";
import classes from "../styles.module.css";


interface DataType extends WorkOrder {
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (text, record) => <a style={{color: "black", fontWeight: "bold"}}
                                     href={`/work_orders/${record.id}`}>{text}</a>,
    },
    {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        render: (notes) => <div style={{maxWidth: "700px", whiteSpace: "pre-wrap"}}>{notes}</div>,
    },
    {
        title: 'Status',
        dataIndex: 'statusColor',
        key: 'status',
        render: (statusColor, record) => (
            <Tag key={record.statusId} color={statusColor} style={{maxWidth: "700px", whiteSpace: "pre-wrap"}}>
                {record.statusName}
            </Tag>
        ),
    },
    {
        title: 'CreatedDate',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: (date) => <div>{formatDate(date)}</div>,
    },
    // {
    //     title: 'UpdatedDate',
    //     dataIndex: 'updatedDate',
    //     key: 'updatedDate',
    //     render: (date) => <a>{date}</a>,
    // },
    // {
    //     title: 'Address',
    //     dataIndex: 'address',
    //     key: 'address',
    // },
    // {
    //     title: 'Tags',
    //     key: 'tags',
    //     dataIndex: 'tags',
    //     render: (_, {tags}) => (
    //         <>
    //             {tags.map((tag) => {
    //                 let color = tag.length > 5 ? 'geekblue' : 'green';
    //                 if (tag === 'loser') {
    //                     color = 'volcano';
    //                 }
    //                 return (
    //                     <Tag color={color} key={tag}>
    //                         {tag.toUpperCase()}
    //                     </Tag>
    //                 );
    //             })}
    //         </>
    //     ),
    // },
    {
        title: 'Actions',
        key: 'action',
        render: (_, record) => <ButtonActions {...record}/>

    },
];

interface TableViewProps {
    data: WorkOrder[] | undefined
}

const TableView: React.FC<TableViewProps> = ({data}) => {


    return (
        <Table<DataType> columns={columns} dataSource={data} scroll={{x: 'max-content'}}/>
    );
};

export default TableView;

const ButtonActions: React.FC<DataType> = ({id, title}) => {

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const queryClient = useQueryClient()
    const [error, setError] = useState("");

    const mutation = useMutation({
        mutationFn: ({id}: { id: string }) =>
            deleteWorkOrder(id),
        onSuccess: async () => {
            setIsModalOpen(false);
            setError("");
            await queryClient.invalidateQueries({queryKey: ['workOrders']}).catch(console.error)
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });

    const handleDeleteWorkOrder = (id: string) => {
        mutation.mutate({id});
    }

    return <Space size="middle">
        <Button type={"text"} onClick={() => navigate(`/work_orders/${id}/edit`)}
                icon={<EditOutlined/>}>Edit</Button>
        <Button type={"text"} onClick={() => {
            navigate(`/work_orders/${id}`)
        }}
                style={{color: "#4A9BFF"}} icon={<ArrowRightOutlined/>}>Details</Button>
        <Button type={"text"} style={{color: "red"}}
                icon={<DeleteOutlined/>} onClick={setIsModalOpen.bind(null, true)}>Delete</Button>
        <WarningModal deleteText={"Delete"} title={"Are you sure you want to delete this work order?"}
                      handler={handleDeleteWorkOrder.bind(this, id)} isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen} data={title}/>
        {error && <Alert message={error} type={"error"} className={classes.alert}/>}
    </Space>
}
