import React from 'react';
import classes from "../styles.module.css"
import Clipboard from "../UI/Clipboard";

interface IDetailsRowProps {
    label: string
    data: string | number | undefined | React.ReactElement
    section: string
    products: any
}

const DetailsRow: React.FC<IDetailsRowProps> = ({label, data, products}) => {

    if (label === "Choose a product type") {
        return (
            <div className={classes.productContainer}>
                <div className={classes.productColumn}>
                    {products && products.length > 0 ? (
                        <>
                            <label style={{fontWeight: "bold", textTransform: "capitalize"}}>Products Installed </label>
                            <ul>
                                {products?.map((i: any) => (
                                    <div key={i.itemNumber}>
                                        <li style={{fontWeight: "bold"}}>{i.productName}</li>
                                        {i.description && <div style={{ whiteSpace: "pre-wrap" }}>Description: {i.description}</div>}
                                        { i.serialNumber || i.serialNumber === "" ? (
                                            <>
                                                {i.quantity === 0 && <div style={{color: "#4096FF"}}>Servis</div>}
                                                {i.quantity === 1 && <div  style={{color: "#4096FF"}}>Install</div>}
                                                {i.quantity === -1 && <div  style={{color: "#4096FF"}}>Uninstall</div>}
                                            </>
                                        ) : (
                                            i.quantity || i.quantity === 0 && <div>Quantity: {i.quantity}</div>
                                        )
                                        }
                                        {i.serialNumber && <div>Serial number: {i.serialNumber} <Clipboard text={i.serialNumber}/></div>}

                                    </div>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <span>Currently no products selected</span>
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            <label style={{fontWeight: "bold", textTransform: "capitalize"}}>{label}: </label>
            <div style={{whiteSpace:"pre-wrap"}}>{data ? data : "Currently no data"}</div>
        </>
    );
};

export default DetailsRow;