// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_row__kAfOG {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
}

.style_search__1DV5L {
    width: 30%;
}


/* Media query for mobile devices with maximum width of 600px */
@media only screen and (max-width: 600px) {
    .style_search__1DV5L {
        width: 100%;
    }
    .style_row__kAfOG {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/work-orders/components/workOrderFilters/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;;AAGA,+DAA+D;AAC/D;IACI;QACI,WAAW;IACf;IACA;QACI,aAAa;QACb,mBAAmB;QACnB,eAAe;IACnB;AACJ","sourcesContent":[".row {\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n    margin-bottom: 10px;\n}\n\n.search {\n    width: 30%;\n}\n\n\n/* Media query for mobile devices with maximum width of 600px */\n@media only screen and (max-width: 600px) {\n    .search {\n        width: 100%;\n    }\n    .row {\n        display: flex;\n        flex-direction: row;\n        flex-wrap: wrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `style_row__kAfOG`,
	"search": `style_search__1DV5L`
};
export default ___CSS_LOADER_EXPORT___;
